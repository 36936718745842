import { Button, Heading, ScrollView, Spinner, XStack, YStack, isWeb, useMedia } from '@mps/ui'
import { BookOpen, Car, HeartPulse, Home } from '@tamagui/lucide-icons'
import { Suspense, useState } from 'react'
import { Link, TextLink } from 'solito/link'
import { HEADER_HEIGHT, Header } from '../Header.web'
import { RemainingDaysTrial, RemainingDaysTrialSkeleton } from '../RemainingDaysTrial'

import { useRouter } from 'next/router'
import { Container } from '../Container'
import { Logo } from '../Logo'
import { LayoutProps } from './Layout'

const SIDEBAR_WIDTH = 280
const SM_BOTTOM_NAV_HEIGHT = 80

const menu = [
  [Home, 'Accueil', '/', (route, pathname) => route === '/'],
  [Car, 'Ma Tournée', '/tournee', (route, pathname) => route.startsWith('/tournee')],
  [
    HeartPulse,
    'Demandes de Soins',
    '/demandes-soins',
    (route, pathname) => route.startsWith('/demandes-soins'),
  ],
  [
    BookOpen,
    'Mon Profil Public',
    '/profil-public',
    (route, pathname) => route.startsWith('/profil-publique'),
  ],
]

const fallback = (
  <YStack jc="center" minHeight="10vh">
    <Spinner size="large" color="$color5" />
  </YStack>
)

export const Layout = ({
  children,
  hasHeader = true,
  hasLogo = false,
  hasCustomHeader = false,
  hasSidebar = false,
  centerContent = false,
  fullscreen = false,
  headerProps = {},
  ...restProps
}: LayoutProps) => {
  const media = useMedia()
  const { route, asPath } = useRouter()

  return (
    <XStack
      f={1}
      position="relative"
      w="100%"
      {...restProps}
      $sm={{ flexDirection: 'column-reverse' }}
    >
      {/* SIDEBAR */}
      {hasSidebar && (
        <YStack
          tag="aside"
          // animation="bouncy"
          bg="$color4"
          position="relative"
          jc="space-between"
          $sm={{
            flexDirection: 'row',
            py: '$4',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            h: SM_BOTTOM_NAV_HEIGHT,
          }}
          $gtSm={{ w: 80, py: '$6' }}
          $gtMd={{
            w: SIDEBAR_WIDTH,
          }}
        >
          {/* HACK FOR EXTERNAL BORDER RADIUS */}
          <XStack
            bg="$color4"
            height="100%"
            width={26}
            position="absolute"
            right={-26}
            bottom={0}
            $sm={{
              display: 'none',
              position: 'fixed',
              w: '100vw',
              h: 26,
              right: 0,
              left: 0,
              bottom: SM_BOTTOM_NAV_HEIGHT,
            }}
            $gtSm={{ top: 0 }}
          >
            <XStack
              bg="$background"
              height="100%"
              width="100%"
              $sm={{ right: 0, top: 0, borderBottomLeftRadius: 28, borderBottomRightRadius: 28 }}
              $gtSm={{ borderTopLeftRadius: 28, borderBottomLeftRadius: 28 }}
            />
          </XStack>

          <YStack gap="$14" $sm={{ flexDirection: 'row', space: 0, flex: 1 }}>
            {/* LOGO */}
            {media.gtSm && (
              <TextLink href="/">
                <Heading
                  tag="div"
                  maxWidth={400}
                  hoverStyle={{ cursor: 'pointer' }}
                  $sm={{ display: 'none' }}
                >
                  <XStack space="$2" ai="center" jc="center">
                    <Logo size={48} />
                    {media.gtMd && (
                      <Heading fontSize="$8" fontWeight="600" cursor="pointer">
                        Mon Petit Soin
                      </Heading>
                    )}
                  </XStack>
                </Heading>
              </TextLink>
            )}

            {/* MENU */}
            <YStack
              als="center"
              space="$3"
              $gtSm={{ w: '80%' }}
              $gtMd={{ w: 260 }}
              $sm={{ flexDirection: 'row', w: '100%', px: '$3' }}
              id="menu"
              jc="space-around"
            >
              {menu.map(
                ([icon, title, href, checkIfCurrent]: [
                  any,
                  string,
                  string,
                  (path: string, asPath?: string) => boolean,
                ]) => (
                  <Link href={href} key={href} viewProps={{ style: { textDecoration: 'none' } }}>
                    <Button
                      tag="button"
                      jc={media.gtMd ? 'flex-start' : 'center'}
                      ai="center"
                      w="100%"
                      $gtSm={{ px: media.gtMd ? null : 0 }}
                      icon={icon}
                      scaleIcon={1.2}
                      animation="bouncy"
                      size="$6"
                      chromeless={!checkIfCurrent(route, asPath)}
                      bc={checkIfCurrent(route, asPath) ? '$backgroundHover' : undefined}
                    >
                      {media.gtMd ? title : ''}
                    </Button>
                  </Link>
                )
              )}
            </YStack>
          </YStack>

          {/* TRIAL PERIOD / PLAN */}
          <Suspense fallback={<RemainingDaysTrialSkeleton />}>
            <RemainingDaysTrial />
          </Suspense>
        </YStack>
      )}
      {/* MAIN */}
      <ScrollView $gtSm={{ height: '100vh' }} id="main">
        <Container
          $sm={{ pb: fullscreen ? 0 : SM_BOTTOM_NAV_HEIGHT, px: '$4' }}
          position="relative"
        >
          {hasHeader && <Header hasLogo={hasLogo || media.sm} {...headerProps} />}

          <YStack
            zIndex={1}
            f={1}
            mt={hasHeader ? HEADER_HEIGHT + 20 : 0}
            mb={fullscreen ? 0 : '$8'}
            ai={centerContent ? 'center' : undefined}
            $sm={{ mb: fullscreen ? 0 : '$4' }}
          >
            {children}
          </YStack>
        </Container>
      </ScrollView>
    </XStack>
  )
}
