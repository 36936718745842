import { Paragraph, Progress, Spinner, XStack, YStack, useMedia } from '@mps/ui'

import { api } from '@mps/pro-app/provider/react-query'
import React, { useMemo } from 'react'
import { Link } from 'solito/link'

export const RemainingDaysTrial = () => {
  const media = useMedia()
  const [user] = api.user.current.useSuspenseQuery()

  const remainingDays = useMemo(() => {
    if (!user?.trialEndsAt) return 0
    const trialEnd = new Date(user.trialEndsAt)
    const now = new Date()
    const diff = trialEnd.getTime() - now.getTime()
    return Math.max(0, Math.ceil(diff / (1000 * 3600 * 24)))
  }, [user?.trialEndsAt])

  const maxDays = useMemo(() => {
    if (!user?.trialEndsAt || !user?.createdAt) return 0
    const trialStart = new Date(user.createdAt)
    const trialEnd = new Date(user.trialEndsAt)
    const diff = trialEnd.getTime() - trialStart.getTime()
    return Math.ceil(diff / (1000 * 3600 * 24))
  }, [user?.createdAt, user?.trialEndsAt])

  if (!media.gtMd || !user?.trialEndsAt || user?.subscription?.status === 'active') return null

  const hasReminingDays = remainingDays > 0

  const Wrapper = ({ children }) =>
    hasReminingDays ? children : <Link href="/abonnement">{children}</Link>

  return (
    <Wrapper>
      <YStack
        bc="$color5"
        br="$4"
        p="$4"
        mx="$4"
        jc="space-between"
        {...(!hasReminingDays && {
          hoverStyle: { scale: 0.98 },
        })}
      >
        {hasReminingDays ? (
          <>
            <XStack jc="space-between" mb="$3">
              <Paragraph fontWeight="500">{`Période d'essai`}</Paragraph>
              <Paragraph fontWeight="300">
                {remainingDays > 1 ? `${remainingDays} jours` : `${remainingDays} jour`}
              </Paragraph>
            </XStack>
            <Progress
              value={((maxDays - remainingDays) / maxDays) * 100}
              max={100}
              height={16}
              bc="$color2"
              minWidth="100%"
            >
              <Progress.Indicator bc="$perano6" />
            </Progress>
          </>
        ) : (
          <YStack jc="space-between">
            <Paragraph size="$5" color="$color10" ta="center">
              Période d'essai terminée
            </Paragraph>
            <Paragraph size="$6" color="$color10" ta="center" fontWeight="700">
              S'abonner maintenant 🚀
            </Paragraph>
          </YStack>
        )}
      </YStack>
    </Wrapper>
  )
}

export const RemainingDaysTrialSkeleton = () => {
  const media = useMedia()
  if (!media.gtMd) return null
  return (
    <YStack bc="$color5" br="$4" p="$4" mx="$4" jc="space-between">
      <XStack jc="space-between" mb="$3">
        <Paragraph fontWeight="500">{`Période d'éssai`}</Paragraph>
        <Spinner size="small" color="$color10" />
      </XStack>
      <Progress value={0} max={90} height={16} bc="$color2" minWidth="100%" />
    </YStack>
  )
}
