import { YStack, styled } from '@mps/ui'

export const Container = styled(YStack, {
  w: 1200,
  maw: '100%',
  mx: 'auto',
  px: '$8',
})

// export const Container = ({ children }) => {
//   const media = useMedia()

//   return (
//     <BaseContainer
//     // {...(media.md && {
//     //   x: 10,
//     //   y: 10,
//     // })}
//     >
//       {children}
//     </BaseContainer>
//   )
// }
